<template>
  <div style="height: inherit">
    <Header
      :mqShallShowLeftSidebar="mqShallShowLeftSidebar"
      :sortBy="sortBy"
      :sortByOptions="sortByOptions"
      :itemView="itemView"
      :itemViewOptions="itemViewOptions"
      @change-view="changeView"
      @sort-by-option="sortByOption"
      @mq-show-sidebar-left="mQShowSidebarLeft"
    />

    <!-- Overlay -->
    <div class="body-content-overlay"/>
    <!-- Searchbar -->
    <Searchbar/>      
    <!-- Products -->      
    <Products :itemView="itemView" :filters="filters" class="mt-1"/>
    <!-- Pagination of products -->      
    <ProductsPagination :totalRows="filteredProducts.length" :filters="filters"/>      
    
  </div>
</template>

<script>
 
import { mapState, mapActions,  mapGetters, mapMutations } from 'vuex'
import Header from '@/modules/shop/components/experiences/Header'
import FiltroSidebar from '@/modules/shop/components/experiences/FiltroSidebar'
import Searchbar from '@/modules/shop/components/experiences/Searchbar'
import Products from '@/modules/shop/components/experiences/Products'
import ProductsPagination from '@/modules/shop/components/experiences/ProductsPagination'
import { itemViewOptions, sortByOptions, filterOptions, filters } from '@/data/data'
import { toJson, overWriteAxiosHeaders } from '@/helpers/helpers'
import { pushImpressionsToDL } from '@/helpers/dataLayerHelpers'


export default {
  created() {  
    this.setQueryParams()
    this.resetToInitialState()
    if( !this.queryParams ){      
      this.setQueryParams()
    }
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    if(!this.queryParams){
      this.$router.push({name: 'not-authorized' })
    }
    
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  async mounted(){  
    if(this.queryParams){
      // hago el login previo y otros procesos desde está función
      await this.handlerQueryParams() 
      // si user es logueado puedo pedir los productos
      if( this.isLogged ){        
        const payload = this.handlerEndPointRequestPayload()
        // console.log( payload )
        if( payload && this.filteredProducts.length == 0 ){
          const { product } = await this.getInitialContent(payload)
          product.serviciosihot = product.detailSelected?.servicesihot || null
          this.addProductToCart( product )
          this.setCurrencyCustomer()   
        }
        if( this.$gtm.enabled() ){
          pushImpressionsToDL() //push impresions to DataLayer           
        } 
      }
    }
    if( this.cart.cliente.customertype == '' ){
      this.setTipoCliente(8) // el tipo de cliente es Directo Web
    }       
  },
  components: {
    Header,
    FiltroSidebar,
    Searchbar, 
    Products,
    ProductsPagination  
  },
  data() {
    return {               
      itemView:'grid-view',
      itemViewOptions,
      sortBy: { text: 'Orden alfabético', value: 'alfabético' },
      sortByOptions,      
      filters,
      filterOptions,
      mqShallShowLeftSidebar: false,  
      params: this.$route.query   
    }
  },
  computed:{
    ...mapState('shop',['cart']),               
    ...mapState('auth',['queryParams','user', 'isLogged']),               
    ...mapState('start',['currencies']),              
    ...mapGetters('products',['filteredProducts']), 
  },
  methods: {
    ...mapActions('products',['getInitialContent']),   
    ...mapActions('auth',['loginWeb']),          
    ...mapMutations('products',['setSortBy','setProducts']),      
    ...mapMutations('shop',['setCurrency','addProductInCart','setHotelCliente','setTipoCliente','setEmptyCart']),
    ...mapMutations('auth',['setDataQueryParams', 'setUser', 'logout']),
    setQueryParams(){        
      const queryParams = this.$route.query //obtengo lo que viene despues del ?  
      const result = (Object.keys(queryParams).length === 0 && queryParams.constructor === Object) ?  null : queryParams
      this.setDataQueryParams(result)      
    },
    async handlerQueryParams(){
      if(this.queryParams.token){
        await overWriteAxiosHeaders( this.queryParams )
        if(this.user) return // si ya hay datos de user en storage no necesito hace login
        const { ok, user } = await this.loginWeb( this.queryParams.token )
        if(ok){
          this.setUser( user )
        }
        if(!ok){
          this.logout()
          this.setDataQueryParams(null)      
          this.$router.push({name: 'not-authorized' })
        }              
      } else {
        this.$router.push({name: 'not-found' })
      }
    },
    changeView(viewType){
      this.itemView = viewType      
    },
    sortByOption(option){
      this.sortBy = option
      this.setSortBy(JSON.parse(JSON.stringify(this.sortBy)))          
    },
    mQShowSidebarLeft(booleano){
      this.mqShallShowLeftSidebar = booleano      
    },       
    setCurrencyCustomer(){      
      const cliente = this.cart.cliente
      const { currency } = cliente
      const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )    
      if( !currency ){
        this.setCurrency( divisa )
      }      
    },
    handlerEndPointRequestPayload(){
      const { type, product, detail, lang } = this.queryParams     
      const isValidType = ['events', 'products', 'packages'].includes(type)
      const isValidQueryParams = ( type && product && detail ) ? true : false
      //si queryParams o tipo de productos son invalidos termino la ejecución        
      if( !isValidType || !isValidQueryParams ){
        return null 
      }   
      const tipo = type == 'events' ? 'eventswebsites' : 'productswebsites'       
      if( lang ){
        const idLangs = [{ idioma: 'es', id: 1 }, { idioma: 'en', id: 2}, { idioma: 'br', id: 3} ]//id langs
        const langValid = ['es', 'en', 'br'].includes(lang) ? lang : 'es' // si lang viene, ver si es válido y usarlo, sino poner default lang como 'es'
        const language = idLangs.find( lang => lang.idioma === langValid ) // busco el id que voy a enviar
        return { idProducto: parseInt(product), idProductDetail: parseInt(detail), type, endPoint: tipo, language: language.id }             
      } else {
        return { idProducto: parseInt(product), idProductDetail: parseInt(detail), type, endPoint: tipo, language: 1 }         
      }        
    },
    addProductToCart( item ){
      if( item ){        
        const { detail: idDetail, type } = this.queryParams // idDetail que servirá para ver que detalle buscar  
        const { detail } = item //listado de detalles del item al que se tiene que buscarle uno para agregar al cart    

        if( type !== "products"){
          delete item.detail
          delete item.detailSelected.currency
          this.addProductInCart(item)                   
        }

        if( type === "products" ){ // si el tipo es products, buscar su id detalle
          const itemDetail = detail.find( detalle => detalle.id == idDetail)        
          if( !itemDetail ) return //sino existe el detalle no sigo
            const { descmax, detaildisplay, extrapax, id, saleprice, hotel} = itemDetail
            item.descmax = parseInt(descmax) || 0
            item.detaildisplay = detaildisplay   
            item.extrapax = extrapax
            item.detailId = id
            item.saleprice = saleprice
            delete item.detail
            delete item.detailSelected.currency
            this.addProductInCart(item)                        
        }

        if( item.hotel ){
          this.setHotelCliente( item.hotel.id )                                             
        }
        //redirijo despues de 2 segundos al resumen de la orden 
        setTimeout(() => {
          this.$router.push({name: 'order-summary'})          
        }, 2000) 

      }      
    },
    resetToInitialState(){
      const thereAreProducts = this.filteredProducts.length > 0
      const isEmptyParams = Object.keys(toJson( this.params )).length === 0
      // si parametros no están vacíos y ya hay productos, reseteo
      if( !isEmptyParams && thereAreProducts){        
        this.setProducts([])
        this.setEmptyCart()
      }
    }
  },  
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>