<template>
  <div>  
    <b-link v-if="product.modelType == 'product'"
      :to="{ name: 'sales-product-details', params: { slug: 'products-'+ product.id } }"
    >
      <b-card-img
        style="height:100%;"        
        :alt="`${product.name}-${product.id}`"
        :src="imgUrl + product.coverImage"
        @error="imgAlternativo"
      />
    </b-link>
    <b-link v-if="product.modelType == 'event'"
      :to="{ name: 'sales-product-details', params: { slug: 'events-'+ product.id } }"
    >
      <b-card-img
        style="height:100%;"        
        :alt="`${product.name}-${product.id}`"
        :src="imgUrl + product.coverImage"
        @error="imgAlternativo"
      />
    </b-link>
    <b-link v-if="product.modelType == 'package'"
      :to="{ name: 'sales-product-details', params: { slug: 'packages-'+ product.id } }"
    >
      <b-card-img
        style="height:100%;"        
        :alt="`${product.name}-${product.id}`"
        :src="imgUrl + product.coverImage"
        @error="imgAlternativo"
      />
    </b-link>    
  </div>
</template>

<script>
 
export default {
  props:{
    product: {
      type: Object,
      required: true
    }    
  }, 
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require('@/assets/images/default.jpg')      
    }
  },  
  methods:{
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    }
  }
}
</script>


<style>
.card-img-top {    
    height: 10rem;
    object-fit: cover;
}
</style>