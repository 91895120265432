<template>
    <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
  <v-select
    :options="options"
    label="detaildisplay"
    v-model="selected"
    @input="setProductoDetalle(selected)"
    class="mb-1 style-chooser"  
  >
    <!-- class="mb-1 select-size-sm"   -->

  </v-select>
                            
</template>

<script>
import { mapMutations } from 'vuex'
import vSelect from 'vue-select'    

export default {
  props:{
    product: {
      type: Object,
      required: true
    }    
  }, 
  components:{
    vSelect,
  }, 
  data(){
    return {  
      filterCriteria: '',
      filteredRows: [], 
      fields: [{ key: 'detaildisplay', label: 'detaildisplay' }],
      options: this.product.detail,
      selected: this.product.detailSelected.detaildisplay || this.product.detailSelected.detailDesc,
      sizes: ['Adulto: isla mujeres nacional y agrego mas texto', 'Medium', 'Large', 'Extra Large']
    }
  },
  methods:{      
    ...mapMutations('products',['setDetailSelectedInProduct']),            
    setProductoDetalle( detailSelected ){
      if( detailSelected ){
        const detalle = JSON.parse(JSON.stringify(detailSelected))        
        this.product.detailSelected = detalle
        this.setDetailSelectedInProduct( this.product )
      }      
    },
    showList( id ){      
      document.getElementById("input"+id).value = ""     
    }  
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>



