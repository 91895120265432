<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <!-- <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Filtros
            </h6>
          </b-col>
        </b-row> -->
        <!-- Filters' Card -->
        <b-card>
           <div class="text-center" v-if="isLoadingCategories">							
              <b-spinner label="Loading..." variant="success"/><br>
              <strong>Cargando Categorías </strong>
            </div>    
            <div v-if="!isLoadingCategories && selectedCategory">
              <!-- Hotels -->
              <div class="multi-range-price">            
                <h6 class="mt-0" :class="colorFilter">
                <feather-icon
                    icon="HomeIcon"
                    size="16"
                  /> Servicios hoteleros
                </h6>                
                <b-form-radio-group 
                  name="cats"            
                  class="categories-radio-group"
                  stacked
                  v-model="selectedCategory.value"
                  :options="categories.hotels"
                  @change="getProducts"
                  :disabled="isloadingProducts"
                />             
              </div>
                                
              <!-- categories -->
              <div class="brands">
                <h6 class="filter-title" :class="colorFilter">
                  <feather-icon
                    icon="ListIcon"
                    size="16"
                  />  Categorías
                </h6>
                
                <b-form-radio-group
                  name="cats"
                  class="brands-radio-group"
                  stacked
                  v-model="selectedCategory.value"
                  :disabled="isloadingProducts"              
                  :options="categories.defaults"
                  @change="getProducts"
                />
                  <!-- v-model="dataCategory" -->
              </div>

              <!-- services -->
              <div class="brands">
                <h6 class="filter-title" :class="colorFilter">
                  <feather-icon
                    icon="PackageIcon"
                    size="16"
                  />  Servicios
                </h6>          
                <b-form-radio-group 
                  name="cats"
                  v-model="selectedCategory.value"              
                  class="brands-radio-group"
                  stacked
                  :disabled="isloadingProducts"
                  :options="categories.services"
                  @change="getProducts"
                />
              </div>
            </div>            
        </b-card>        
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
//seeds data
import { toJson } from '@/helpers/helpers'

export default {
  
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },  
  data() {
    return {
      
    }
  },
  computed:{
    ...mapState('appConfig',['layout']),
    ...mapState('start',['categories','selectedCategory', 'isLoadingCategories']),
    ...mapState('products',['isloadingProducts']),
    colorFilter(){
      return this.layout.skin === 'dark' ? 'title-filter-dark' : 'title-filter-light'
    },
    
  },
  methods:{    
    ...mapActions('products',['fetchProducts','fetchProductsByCategories','fetchPackages','fetchEvents']),
    ...mapMutations('start',['setSelectedCategory']),
    ...mapMutations('products',['setProducts']),

    async getProducts( valueCategory ){  
      let products = []      
      const { defaults, hotels, services } = this.categories
      const myCategories =  [...defaults, ...hotels, ...services ]
      const categoria = toJson(myCategories.find( cat => cat.value === valueCategory ) ) //category para la peticion     
      
      const { action } = categoria
      
      if( action == 'get-products-best-seller' ){
        products = await this.fetchProducts() 
      }
      if( action == 'get-products-by-category'){
        products = await this.fetchProductsByCategories( categoria )         
      }        
      if( action == 'get-packages' ){
        products = await this.fetchPackages()               
      } 
      if( action == 'get-events' ){
        products = await this.fetchEvents()                       
      }

      this.setSelectedCategory( categoria ) 
      localStorage.setItem('categorySetted', JSON.stringify(categoria)) 
      this.setProducts( products )
      this.$emit('set-current-page')                 
    },   
}
}
</script>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.title-filter-dark {
  color: white;
  font-weight: bold;
}

.title-filter-light {
  color: black;
  font-weight: bold;
}
</style>
