<template>
  <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filter.query"
              placeholder="Product Search"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {    
  computed:{    
    ...mapState('products',['filter']),
  },
}
</script>


