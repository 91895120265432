<template>
  <div>

    <h5 class="item-name" v-if="product.modelType == 'product'">
      <b-link
        class="text-body"
        :to="{ name: 'sales-product-details', params: { slug: 'products-'+ product.id } }"
      > {{ product.name }}
      </b-link>
      <!-- <b-card-text class="mt-1 text-muted"> Categoría: {{product.categoryName}}
      </b-card-text> -->
    </h5>

    <h5 class="item-name" v-if="product.modelType == 'event'">
      <b-link
        class="text-body"
        :to="{ name: 'sales-product-details', params: { slug: 'events-'+ product.id } }"
      > {{ product.name }}
      </b-link>
      <!-- <b-card-text class="mt-1"> Categoría:{{product.categoryName}}
      </b-card-text> -->
    </h5>

    <h5 class="item-name" v-if="product.modelType == 'package'">
      <b-link
        class="text-body"
        :to="{ name: 'sales-product-details', params: { slug: 'packages-'+ product.id } }"
      > {{ product.name }}
      </b-link>
        <!-- <b-card-text class="mt-1"> Categoría: {{product.categoryName}}
      </b-card-text> -->
    </h5>

  </div>
</template>

<script>
 
export default {
  props:{
    product: {
      type: Object,
      required: true
    }    
  }
}
</script>


